import React from "react";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import Typography from "@material-ui/core/Typography";
import {
  MovingProcessContainer,
  ProcessContainer,
  IconContainer,
  InformationContainer,
} from "./moving-process-elements";
import BookingIcon from "../../svgs/book.svg";
import DetailsIcon from "../../svgs/details.svg";
import SurveyIcon from "../../svgs/survey.svg";

function MovingProcess() {
  return (
    <>
      <Typography variant="h3" component="h3">
        Your Moving Process
      </Typography>

      <MovingProcessContainer>
        <ScrollAnimation animateIn="animate__fadeIn" duration={1} animateOnce>
          <ProcessContainer>
            <IconContainer>
              <BookingIcon />
            </IconContainer>
            <InformationContainer>
              <Typography variant="h6" component="h6">
                Book Us
              </Typography>
              <Typography variant="body1" component="p">
                The first step to getting your move started with Brother Bear Moving Company is to{" "}
                <Link to="/contact-us">contact us</Link>. Use our website to get a free quote -
                don't bother waiting on the phone. Simply fill out the form and we will contact you
                so that you don't miss a thing! We will discuss your moving needs, property size,
                pricing, location, date and more in order to put together the right move for you.
              </Typography>
            </InformationContainer>
          </ProcessContainer>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeIn" duration={1} animateOnce>
          <ProcessContainer reverseOrder>
            <InformationContainer isLeftSidedText>
              <Typography variant="h6" component="h6">
                The Move
              </Typography>
              <Typography variant="body1" component="p">
                After your move has been booked, we will bring our team of professional movers and
                equipment to your property - we plan our moves to make your experience smooth. Your
                Brother Bear Movers will take care of organzing, packing, securing and loading your
                items into our truck. Our experience allows us to transport fragile items, move
                heavy appliances, maneuver multi-story complexes and more with ease.
              </Typography>
            </InformationContainer>
            <IconContainer>
              <DetailsIcon />
            </IconContainer>
          </ProcessContainer>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeIn" duration={1} animateOnce>
          <ProcessContainer>
            <IconContainer>
              <SurveyIcon />
            </IconContainer>
            <InformationContainer>
              <Typography variant="h6" component="h6">
                Quality Assurance
              </Typography>
              <Typography variant="body1" component="p">
                Your favorite local movers care about you and the quality of the service that you
                have received. After your move, we like to call and check on how your move went. Our
                great representatives appreciate your feedback so we can make the next moving
                experience even better!
              </Typography>
            </InformationContainer>
          </ProcessContainer>
        </ScrollAnimation>
      </MovingProcessContainer>
    </>
  );
}

export default MovingProcess;
