import styled from "styled-components";
import img from "../../images/background.jpg";

export const Mask = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 95vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.2);
  margin-top: 80px;

  @media screen and (max-width: 960px) {
    margin-top: 48px;
  }
`;

export const JumbotronContainer = styled.div`
  background: url(${img}) center center/cover no-repeat;
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
`;

export const ContentContainer = styled.div`
  z-index: 1;
  margin: auto;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`;
