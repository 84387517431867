import styled from "styled-components";

export const MovingProcessContainer = styled.div`
  margin: auto;
  padding: 64px 64px;

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

export const ProcessContainer = styled.div`
  margin-bottom: 128px;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1300px) {
    align-items: center;
  }

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: ${({ reverseOrder }) => (reverseOrder ? "column-reverse" : "column")};
    justify-content: center;
    align-items: center;
  }
`;
export const IconContainer = styled.div`
  width: 450px;

  @media screen and (max-width: 1300px) {
    width: 500px;
    padding: 32px 0;
  }

  @media screen and (max-width: 700px) {
    width: 450px;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;

export const InformationContainer = styled.div`
  text-align: left;
  max-width: 50%;
  width: 50%;

  @media screen and (min-width: 1300px) and (max-width: 1600px) {
    padding: ${({ isLeftSidedText }) => (isLeftSidedText ? "0 40px 0 0" : "0 0 0 40px")};
  }

  @media screen and (max-width: 1300px) {
    padding-top: 16px;
    max-width: 75%;
    width: 75%;
  }

  @media screen and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
  }
`;
