import { Link } from "gatsby";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Mask, JumbotronContainer, ContentContainer, ButtonContainer } from "./jumbotron-elements";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  text: {
    color: "white",
  },
});

function Jumbotron() {
  const classes = useStyles();
  return (
    <JumbotronContainer>
      <Mask />
      <ContentContainer>
        <Typography variant="h1" component="h1" className={classes.text}>
          Quick, Easy & Affordable
        </Typography>

        <Typography variant="subtitle1" component="p" className={classes.text}>
          Let us book your next move the right way.
        </Typography>

        <ButtonContainer>
          <Link to="/contact-us" className={classes.link}>
            <Button size="large" color="primary" variant="contained">
              Get A Quote
            </Button>
          </Link>
        </ButtonContainer>
      </ContentContainer>
    </JumbotronContainer>
  );
}

export default Jumbotron;
