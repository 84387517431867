import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Jumbotron from "../components/jumbotron/jumbotron";
import ContentContainer from "../components/content-container/content-container";
import MovingProcess from "../components/moving-process/moving-process";
import Services from "../components/services/services";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbotron />
    <ContentContainer isCentered>
      <Services />
      <MovingProcess />
    </ContentContainer>
  </Layout>
);

export default IndexPage;
