import styled from "styled-components";

export const Break = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    flex-basis: 100%;
    height: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin: auto;
  padding-bottom: 64px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 64px 0;

  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
`;

export const Item = styled.div`
  display: block;
  align-items: left;
  width: 240px;

  @media screen and (max-width: 520px) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

export const SVG = styled.div`
  width: 80px;
  height: 80px;
`;
