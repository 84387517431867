import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";
import React from "react";
import { Break, ButtonContainer, ItemContainer, Item, SVG } from "./services-elements";
import Labor from "../../svgs/labor.svg";
import Packing from "../../svgs/packing.svg";
import Distance from "../../svgs/distance.svg";
import Commercial from "../../svgs/commercial.svg";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
  },
  text: {
    marginLeft: 12,
    textAlign: "left",
  },
  title: {
    paddingTop: 64,
  },
});

const Services = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" component="h3" className={classes.title}>
        The right tools for the job
      </Typography>

      <ItemContainer>
        <Item>
          <SVG>
            <Labor />
          </SVG>
          <Typography variant="h5" component="h5" className={classes.text}>
            Labor Only
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary" className={classes.text}>
            Reserve a team of movers to help turn your laborious task into a smooth process.
          </Typography>
        </Item>
        <Item>
          <SVG>
            <Packing />
          </SVG>
          <Typography variant="h5" component="h5" className={classes.text}>
            Packing Only
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary" className={classes.text}>
            Get a team of professional movers to gather, pack and organize your belongings.
          </Typography>
        </Item>
        <Break />
        <Item>
          <SVG>
            <Distance />
          </SVG>
          <Typography variant="h5" component="h5" className={classes.text}>
            Long Distance
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary" className={classes.text}>
            Moving to a different city can be a hassel. Our dedicated team will make it seem easy.
          </Typography>
        </Item>
        <Item>
          <SVG>
            <Commercial />
          </SVG>
          <Typography variant="h5" component="h5" className={classes.text}>
            Commerical
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary" className={classes.text}>
            Do you own a business? Don't trust just anyone with your move - trust your local,
            professional movers.
          </Typography>
        </Item>
      </ItemContainer>

      <ButtonContainer>
        <Link to="/moving" className={classes.link}>
          <Button size="large" color="primary" variant="outlined">
            View Service Details
          </Button>
        </Link>
      </ButtonContainer>
    </>
  );
};

export default Services;
